.filterSearchContainer {
    display: flex;
    width: 100%;
    margin-top: 5vh;
}

table {
    width: 100%;
}

.table-wrapper {
    overflow-x: auto;
    width: 100%;
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1vh;
    gap: 0.5rem;
}

.pagination button {
    background-color: #da1a3434;
    border: 1px solid #da1a35 !important;
    border-radius: 5px;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px 20px;
    margin: 0 5px;
}

.pagination button.dots,
.pagination button.dots:hover {
    cursor: default;
    background-color: transparent !important;
    border: none !important;
    color: #fff;
    font-size: 1.2rem;
}

.pagination button.active {
    background-color: #da1a35;
    color: white;
}

.pagination button:hover:not(.active) {
    background-color: #da1a35;
    color: white;
}

/* .filterCheckBoxes {
     width: 50%;
     display: flex;
     justify-content: space-evenly;
     align-self: center;
 }  */

@media (max-width: 1024px) {
    .filterSearchContainer {
        flex-direction: column;
    }

    @media (min-width: 320px) and (max-width: 920px) {
        .filterSearchContainer {
            /* display: inline-flex; */
            width: 100%;
            margin-top: 5vh;
            justify-content: center;
            padding: 5%;

            /* background-color: #00000052; */
        }
    }

    .filterCheckBoxes {
        color: #ffffff;
        width: 28%;
        align-content: center;
        margin: 15px auto;
    }

    .row {
        display: flex;
        justify-content: space-between;
        height: 106px;
    }

    .searchButton {
        width: 100%;
        padding: 5%;
    }
}

/* TABLE STYLE */

table,
th,
td {
    /* border: solid 1px #000; */
    text-align: left;
    align-self: center;
    padding: 10px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    /* border: 1px solid #ddd; */
    margin: 0 auto;
}

tbody {
    background-color: rgba(242, 242, 242, 0%);
    color: white;
}

caption {
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
}

tr:nth-child(odd) {
    background-color: #121212;
}

tr:nth-child(even) {
    background-color: #212121;
}

td img {
    cursor: pointer;
}

@media (max-width: 500px) {
    table,
    th,
    td {
        padding: 4px;
        font-size: 13px;
    }

    table {
        width: 100%;
    }

    td img {
        width: 20px;
        max-width: none;
    }
}
