.holder {
    display: inline;
    padding: 5px;
}
.row-reverse {
    display: flex;
    flex-direction: row-reverse;
}
/* @media (min-width:601px) and (max-width:769px){
  .row-reverse{
    display: flex;
    flex-direction: row-reverse;
  }
} */

.styles_scroll-to-top__2A70v {
    background-color: #da1a35 !important;
    color: white !important;
}

.styles_scroll-to-top__2A70v:hover {
    background-color: #ffffff !important;
    color: #da1a35;
}
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #ffffff !important;
    text-align: left;
    background-color: #212529 !important;
    border: unset;
    border-radius: 0;
    overflow-anchor: none;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
}
.accordion-body {
    padding: 1rem 1.25rem;
    background: #1b1b1dfc;
}
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 0.9rem !important;
    color: #212529;
    font-weight: 900;
    text-align: left;
    background-color: #ffffff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        border-radius 0.15s ease;
}
.accordion-button:after {
    flex-shrink: 0;
    width: 1.25rem;
    border-radius: 2rem;
    height: 1.25rem;
    position: relative;
    right: 2rem;
    /* top: -1.5rem; */
    color: #da1a35 !important;
    /* margin-left: 34rem !important; */
    content: "";
    background-color: #333235 !important;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
}
@media (max-width: 600px) {
    .accordion-button:after {
        flex-shrink: 0;
        width: 1.25rem;
        border-radius: 2rem;
        height: 1.25rem;
        position: relative;
        right: -0.6rem;
        top: 1.4rem;
        color: #da1a35 !important;
        /* margin-left: 34rem !important; */
        content: "";
        background-color: #333235 !important;
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform 0.2s ease-in-out;
    }
}
.accordion-button:not(.collapsed) {
    color: #ffffff;
    background-color: #1b1b1dfc;
    /* box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); */
}
.accordion-item {
    background-color: #1c1e21 !important;
    border: none !important;
}
