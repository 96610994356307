.navBar {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 25px;
}

nav li {
    position: relative;
}

.imgFluid {
    position: relative;
    top: 0em;
    max-width: 100%;
    height: auto;
}
@media (max-width: 600px) {
    .imgFluid {
        position: relative;
        top: 0em;
        max-width: 65%;
        height: auto;
    }

    .navBar {
        height: auto !important;
    }
}
.navBarplus {
    width: 45px;
    height: 45px;
}
.navBarplus:hover {
    background-color: #ffffff;
    border-radius: 4em;
}
.navBarIcons {
    width: 45px;
    height: 45px;
}
@media (max-width: 600px) {
    .navBarIcons {
        width: 45px;
        height: 40px;
        transform: translate(14px, 0px);
    }
    .navBarplus {
        transform: translate(10px, 0px);
        height: 40px;
    }
}
.navBarIcons:hover {
    background-color: #da1a35;
    border-radius: 4em;
}
.menuLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    list-style-type: none;
    width: 35vw;
    padding: 0;
}

.navBar:first-child,
.navBar:nth-child(2) {
    flex: 1 0 33%;
}

.navBar:last-child {
    flex-basis: 100%;
    display: flex;
}

.pseudoItem {
    width: 87px;
}

.pendingsCounter {
    position: absolute;
    top: -10px;
    right: -5px;
    width: 24px;
    height: 24px;
    text-align: center;
    color: white;
    border-radius: 50%;
    background: #da1a35;
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 24px;
}

@media (max-width: 600px) {
    .pendingsCounter {
        position: absolute;
        top: -10px;
        right: -21px;
        width: 24px;
        height: 24px;
        text-align: center;
        color: white;
        border-radius: 50%;
        background: #da1a35;
        font-size: 0.9rem;
        font-weight: 600;
        line-height: 24px;
    }

    .menuLinks {
        width: 40vw;
        transform: translate(-22px, 10px);
    }

    .pseudoItem {
        display: none;
    }
}

@media (min-width: 600px) and (max-width: 1024px) {
    .menuLinks {
        width: 50%;
        margin-top: 5%;
    }
}

#perspective-text {
    display: flex;
    justify-content: center;
    color: #ffffff;
}
.complete {
    transform: translate(-14px, 0px);
}
@media (max-width: 600px) {
    #perspective-text {
        display: flex;
        justify-content: center;
        color: #ffffff;
        font-size: 0.6rem;
    }
    .new {
        transform: translate(8px, -6px);
    }
    .complete {
        transform: translate(14px, -6px);
    }
}
