/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.26, autoprefixer: v9.7.3) */

.filterSearchContainer {
    display: inline-flex;
    width: 90%;
    margin-top: 5vh;
    justify-content: space-between;
    padding: 5%;
    /* background-color:#00000052 */
}

.filterCheckBoxes {
    color: #ffffff;
    width: 20%;
    margin: 1.5em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}
@media (max-width: 992px) {
    .btn--block {
        width: 26% !important;
    }
}
.btn {
    color: #ffffff;
    /* border: 1px solid #cccccc; */
    background-color: #2f3034;
    padding: 0.5rem;
    text-transform: lowercase;
    border-radius: 0.8em;
    border-width: 0ch;
}
.accordion-button:not(.collapsed):after {
    fill: #3e3e42 !important;
    transform: rotate(-180deg);
}
.delete__button {
    font-weight: 400;
    position: absolute;
    top: 0.88em;
    right: 10px;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50%;
    color: #3e3e42;
    background-color: #da1a35;
    font-size: 1rem;
    font-weight: 900;
    line-height: 24px;
    cursor: pointer;
}
.delete__button:hover {
    font-weight: 900;
    color: #da1a35;
    background-color: #3e3e42;
}
.btn--block {
    cursor: pointer;
    display: block;
    width: 10%;
    margin: 1rem auto;
    text-transform: capitalize;
}
.btn--block:hover {
    cursor: pointer;
    background-color: #da1a35;
}
.cards {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: space-around;
}
@media (max-width: 600px) {
    .cards {
        width: 16rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        justify-content: space-around;
    }
}
.cards__item {
    /* display: -webkit-box; */
    /* display: flex; */
    padding: 1rem;
}

@media (min-width: 20rem) {
    .cards__item {
        width: 100%;
    }
}

@media (min-width: 21rem) {
    .cards__item {
        width: 50%;
    }
}

@media (min-width: 56rem) {
    .cards__item {
        width: 22%;
    }
}

.card {
    border-radius: 0.8em;
    width: 60em;
    /* background-color: white; */
    -webkit-box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

@media (max-width: 1200px) {
    .card {
        border-radius: 0.8em;
        width: 35em;
        /* background-color: white; */
        box-shadow: 0 20px 40px -14px rgb(0 0 0 / 25%);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
    }
}
.card:hover .card__image {
    -webkit-filter: contrast(100%);
    filter: contrast(100%);
}

.card__content {
    background-color: #1716188a;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1rem;
}

.card__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    -webkit-filter: contrast(70%);
    filter: contrast(70%);
    overflow: hidden;
    position: relative;
    -webkit-transition: -webkit-filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
    transition: -webkit-filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
    -o-transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
    transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
    transition:
        filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91),
        -webkit-filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
}

.card__image::before {
    content: "";
    display: block;
    padding-top: 56.25%;
}

@media (min-width: 40rem) {
    .card__image::before {
        padding-top: 66.6%;
    }
}

.card__image--room {
    background-image: url(https://www.escapegameover.com/img/event.jpg);
}

.card__title {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.card__text {
    color: #ffffff;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    font-size: 0.875rem;
    line-height: 1.5;
    margin: 0;
    margin-top: 0.375rem;
}

textarea {
    resize: none;
}

@media (max-width: 500px) {
    .btn--block {
        width: 45%;
    }
}

.resultP {
    margin: 15vh auto;
    font-size: 22px;
}

#options {
    border: 2px solid #da1a35;
    margin: 1em;
    border-radius: 0.2em;
}
#options:hover {
    background-color: #da1a35;
    color: #0a0a0a;
}

.popupContainer {
    border: 2px solid #da1a35;
    border-radius: 0.4em;
    position: fixed;
    left: 50%;
    top: 50%;
    color: #da1a35;
    transform: translate(-50%, -50%);
    background-color: #0a0a0a;
    z-index: 9999;
    width: 20%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.popupContainer div {
    cursor: pointer;
}

.popupContainer div:hover {
    cursor: pointer;
    color: #da1a35;
}

.popupOverlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10;
    top: 0;
    left: 0;
    overflow: hidden;
}
