.actions {
    display: flex;
    justify-content: stretch;
    gap: 2em;
    padding: 1em;
}

.actions-button {
    min-width: 8em;
}
.create-report {
    width: 80%;
    margin: auto;
    padding: 1em;
    background: aliceblue;
    border-radius: 0.6em;
}
.t-header {
    color: white !important;
}

.checklist-item {
    display: grid;
    grid-template-columns: 50% 50%;
}
