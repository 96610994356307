.errorMessage {
    color: red;
    text-align: center;
    margin-bottom: 5vh;
    font-size: 21px;
}

.successMessage {
    color: green;
    text-align: center;
    margin-bottom: 5vh;
    font-size: 21px;
}

.formItem p {
    color: white;
    margin-top: 5vh;
}

label {
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 80px;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}
@media (max-width: 992px) {
    label {
        font-size: 1.4em;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 80px;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
    }
}

@media (max-width: 600px) {
    label {
        font-size: 0.8em;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 80px;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
    }
}

.row {
    display: flex;
    justify-content: space-between;
    height: 158px;
}

.checkRow {
    display: flex;
    justify-content: space-between;
    height: 150px;
}

.label {
    line-height: 50px;
}

.textColumn {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.textColumn textarea {
    border-radius: 9px;
    outline: none;
}

.checkBoxStyle {
    width: 50px;
    height: 50px;
}

#root_solution {
    display: flex;
    background-color: #3f3f3f;
    color: white;
    border: none;
    border-radius: 9px;
    width: 100%;
    height: 150px;
    outline: none;
    padding: 5px;
    margin-bottom: 5px;
    flex-direction: column;
    text-transform: capitalize;
}

.submitButton {
    border-radius: 30px;
    background-color: #da1a35;
    border: none;
    text-align: center;
    float: left;
    width: 150px;
    height: 40px;
    position: relative;
    color: white;
    font-weight: 900;
    font-size: 18px;
    color: #ffffff;
    border-radius: 7px;
    line-height: 40px;
    cursor: pointer;
    text-transform: uppercase;
}
@media (max-width: 600px) {
    .submitButton {
        transform: translate(-5%, 0rem);
    }
}

.submitButton:hover {
    font-weight: 900;
    background-color: #ffffff;
    color: #da1a35;
}

@media (min-width: 992px) {
    .submitButton {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        right: 5%;
        top: 3rem;
    }
}

.formItem .optionDropDown {
    margin-bottom: 2vh;
}

.form_input {
    display: flex;
    flex-direction: column;
}

.form_input > textarea {
    color: #ffffff;
}

.optionDropDown select {
    position: relative;
    background-color: #3f3f3f;
    color: white;
    padding: 0.5em;
    width: 100%;
    border: none;
    font-size: 16px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: button;
    outline: none;
    border-radius: 9px;
}

.optionDropDown select {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23ffffff%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius: 0.5em;
    border: none;
    color: #ffffff;
    padding: 10px 30px 10px 10px;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.optionDropDown:hover::before {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
}

.optionDropDown select option {
    padding: 30px;
    outline: none;
    font-size: 16px;
}

select::-ms-expand {
    display: none;
}

#ticketForm__checkbox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-self: center;
    margin-top: 20px;
    flex-wrap: wrap;
    flex-direction: column;
}

#ticketForm__checkbox .checkRow p {
    margin-top: 15px;
}

.modal_message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffffb4;
    width: 25%;
    height: 25%;
    z-index: 9999;
    text-align: center;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
}

.error_message {
    color: rgb(255, 8, 0);
}

.success_message {
    color: rgb(2, 146, 2);
}

.solutionInput {
    display: flex;
    flex-direction: column;
}

.solutionInput input[name="solution"] {
    background-color: #3f3f3f;
    color: white;
    border: none;
    border-radius: 9px;
    width: 100%;
    height: 150px;
    outline: none;
    padding: 5px;
    margin-bottom: 5px;
}
