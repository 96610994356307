form {
    z-index: 1030;
    display: flex;
    flex-direction: column;
}

form input {
    background: #696a6c;
    border-color: transparent;
    width: 250px;
    height: 30px;
    outline-color: transparent;
    border-radius: 0.5rem;
    color: white;
    background-color: rgba(255, 255, 255, 7%);
    font-size: 18px;
    margin: 0.1rem;
}

form button {
    z-index: 1030;
    background: #696a6c;
    border-color: transparent;
    width: 100px;
    height: 35px;
    outline-color: transparent;
    border-radius: 5px;
    color: #ffffff;
    background-color: #da1a35;
    font-size: 21px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
}
form button:hover {
    color: #da1a35;
    background-color: #ffffff;
}

.logOutButton {
    background: #696a6c;
    border-color: transparent;
    width: 100px;
    height: 35px;
    outline-color: transparent;
    border-radius: 5px;
    color: #ffffff;
    background-color: #da1a35;
    font-size: 21px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
    font-weight: 400;
    display: flex;
    justify-content: center;
    margin-top: 10%;
}

.logOutContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: auto;
}

@media (max-width: 600px) {
    h1 {
        width: 1rem;
    }
    .logOutButton {
        float: right;
        background: #696a6c;
        border-color: transparent;
        width: auto;
        outline-color: transparent;
        border-radius: 5px;
        color: #ffffff;
        background-color: #da1a35;
        font-size: 21px;
        text-align: center;
        margin: 5px;
        cursor: pointer;
        font-weight: 400;
        display: flex;
        justify-content: center;
        transform: translate(1%, 28%);
    }
}

.logOutButton:hover {
    color: #da1a35;
    background-color: #ffffff;
    font-weight: 400;
}
.login {
    display: flex;
    justify-content: center;
    transform: translate(60%);
}

@media (min-width: 600px) and (max-width: 1024px) {
    .logOutButton {
        width: auto;
    }
}

@media (max-width: 1024px) {
    .logOutContainer > h1 {
        font-size: 1.3rem;
        text-align: center;
    }
}
