.searchBar___container {
    position: relative;
    height: 0.1em;
    margin: 2% auto;
    width: 100%;
    max-width: 30rem;
}

#searchBar input[type="text"] {
    height: 2em;
    font-size: 1.4rem;
    display: inline-block;
    font-weight: 100;
    border: none;
    text-align: center;
    outline: none;
    color: white;
    padding: 3px;
    padding-right: 60px;
    width: 0px;
    position: absolute;
    top: 0.2em;
    right: 0;
    background: none;
    z-index: 3;
    -webkit-transition: width 2.5s cubic-bezier(0, 0.795, 0, 1);
    -o-transition: width 2.5s cubic-bezier(0, 0.795, 0, 1);
    transition: width 2.5s cubic-bezier(0, 0.795, 0, 1);
    cursor: pointer;
}

#searchBar input:hover {
    border: 5px solid #121212;
}

#searchBar input[type="text"] {
    width: 100%;
    z-index: 1;
    /* border-bottom: 1px solid white; */
    background-color: #202020;
    border-radius: 1rem;
    cursor: text;
}

#searchBar input[type="submit"] {
    height: 2rem;
    width: 2rem;
    display: inline-block;
    color: red;
    background: url("assets/search.svg") center center no-repeat;
    text-indent: -10000px;
    border: none;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 2;
    cursor: pointer;
    opacity: 0.4;
    cursor: pointer;
    /* -webkit-transition: opacity 2.5s ease;
    -o-transition: opacity 2.5s ease;
    transition: opacity 2.5s ease; */
}

#searchBar input[type="submit"]:hover {
    opacity: 0.8;
}

@media (max-width: 600px) {
    .searchBar___container {
        width: 85%;
    }

    #searchBar input[type="submit"] {
        width: 1.7rem;
        height: 1.7rem;
        right: 0.5rem;
    }

    #searchBar input[type="text"] {
        font-size: 1.3rem;
    }
}
