*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.back {
    background-image: url("../src/assets/background.png");
    background-repeat: repeat;
    background-size: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 5%;
}

@media (max-width: 1400px) {
    .back {
        background: #0a0a0a;
    }
}

.blur-div {
    width: 100%;
    bottom: 0;
    height: 200px;
    position: absolute;
    background: linear-gradient(to bottom, transparent 40%, #0e0e0e 100%);
    z-index: 2;
}

.scrollable {
    height: 32em;
    overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgb(14 14 14);
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 0.5em;
    background: rgb(43, 41, 41);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #da1a35;
}

@font-face {
    font-family: Proxima-Nova;
    src: url(./assets/font/ProximaNovaLight.ttf);
}

html {
    background-color: rgb(14 14 14) !important;
    height: 100%;
}

body {
    color: #ffffff;
    background-color: #080403;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
}

.App {
    width: 100%;
}

h1 {
    color: #ffffff;
    text-align: center;
    width: 6em;
    height: 1.4em;
    margin-top: 0.2em;
    border-radius: 0.2em;
    background: #0a0a0a87;
}

.buttonAdd {
    width: 55px;
    height: 55px;
    background-image: url("./assets/addbutton.png");
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 15px auto;
}

.ticket {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 30%;
    margin: 0 auto;
}

.loginPopupContainer {
    position: fixed;
    left: 50%;
    top: 50%;
    color: white;
    transform: translate(-50%, -50%);
    z-index: 12;
    max-width: 300px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

fieldset {
    border: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    flex-direction: column;
    width: auto;
    padding: 0;
}

fieldset:first-child {
    display: grid;
    width: 100%;
    gap: 0.5rem;
}

textarea {
    background-color: #3f3f3f;
    border: none;
    border-radius: 9px;
}

.field-string {
    display: flex;
    flex-direction: column;
}

.field-string > input {
    background-color: #3f3f3f;
    color: white;
    width: auto;
    height: auto;
}

.field-string > input::placeholder {
    color: rgba(255, 255, 255, 0.589);
    font-size: 1rem;
}

.field-string:last-of-type > label {
    margin-top: 2rem;
    font-size: 2rem;
}

.control-label {
    font-size: 1.1rem;
    margin-left: 1%;
    text-transform: capitalize;
}

.backButton {
    background-color: #da1a35;
    border: none;
    text-align: center;
    float: right;
    width: 75px;
    height: 40px;
    position: relative;
    color: white;
    font-weight: 100;
    font-size: 18px;
    color: #ffffff;
    border-radius: 7px;
    line-height: 40px;
    cursor: pointer;
    margin: 15px;
}

.backButton:hover {
    background-color: #ffffff;
    color: #da1a35;
}

select:disabled {
    opacity: 1;
}

.removeButton > div:last-of-type {
    display: none;
}
