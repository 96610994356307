.action {
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin: auto;
}

.action > * {
    width: 30%;
}
